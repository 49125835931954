import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "bootstrap/dist/css/bootstrap.min.css";

import { headData } from "../mock/data";
import "../style/main.scss";

export default () => {
  const { lang } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terminos y condiciones</title>
        <html lang={lang || "en"} />
        <meta name="Terminos y condiciones" content="Terminos y condiciones" />
      </Helmet>
      <section id="hero" className="jumbotron">
        <Container>
          <Fade bottom duration={500} delay={100} distance="0px">
            <h1 className="hero-title text-center">TÉRMINOS Y CONDICIONES</h1>
          </Fade>
          <Fade duration={1000} delay={100}>
            <h2>I.- RELACI&Oacute;N CONTRACTUAL.</h2>
            <p>
              Los presentes T&eacute;rminos y Condiciones de uso
              (&ldquo;T&eacute;rminos y Condiciones&rdquo;) regulan el acceso o
              uso que cualquier persona o entidad (en adelante el
              &ldquo;Usuario&rdquo;), realice en el sitio de internet hptt: www.
              Konter.com.mx (sucesivamente la &ldquo;Sitio Web&rdquo;) o del
              servicio puesto a disposici&oacute;n por GRUPO CONTABLE Y
              TECNOL&Oacute;GICO DEL SURESTE siendo una Sociedad An&oacute;nima
              Promotora de Inversi&oacute;n de Capital Variable constituida bajo
              las leyes de la Rep&uacute;blica Mexicana, con domicilio en la
              oficina PH 08 de la Torre Magnia ubicada de la calle 15, numero
              503 por 18 y 12, Altabrisa, M&eacute;rida Yucat&aacute;n, CP
              97130, en adelante (&ldquo;<em>KONTER</em>&rdquo;).
            </p>
            <p>
              <br />
            </p>
            <h2>
              ANTES DE ACCEDER AL SERVICIO ES NECESARIO LEER Y ACEPTAR LOS
              PRESENTES T&Eacute;RMINOS Y CONDICIONES.
            </h2>
            <p>
              Mediante el acceso al Sitio Web y el uso del Servicio, el Usuario
              se obliga a vincularse jur&iacute;dicamente por estos
              T&eacute;rminos y Condiciones, que establecen una relaci&oacute;n
              contractual entre el Usuario y KONTER. En caso de que el Usuario
              no acepte los presentes T&eacute;rminos y Condiciones, no
              tendr&aacute; acceso al uso del Servicio que KONTER presta en su
              Sitio Web. Es decir, podr&aacute; navegar en el Sitio Web para ver
              lo que se ofrece en ella, pero no podr&aacute; realizar
              acci&oacute;n alguna en dicho Sitio Web, hasta no aceptar los
              t&eacute;rminos y condiciones y registre sus datos. As&iacute;
              bien, los T&eacute;rminos y Condiciones y/o el Servicio,
              podr&aacute;n ser modificados o dejados sin efectos de manera
              total o parcial cuando lo considere oportuno KONTER, mediante
              notificaci&oacute;n por correo electr&oacute;nico y/o aviso o
              advertencia en la pantalla principal del Sitio Web.
            </p>
            <p>
              <br />
            </p>
            <p>
              La recopilaci&oacute;n y el uso que hacemos de la
              informaci&oacute;n personal en relaci&oacute;n con el Servicio, se
              encuentra relacionada directamente a lo dispuesto en el Aviso de
              Privacidad de KONTER, disponible en www. Konter.com,mx cumpliendo
              con la normatividad establecida en la Ley Federal de
              Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los
              Particulares. Los v&iacute;nculos que pudiera contener el Sitio
              Web pueden dirigir al Usuario a sitios web de terceros que no son
              propiedad ni est&aacute;n bajo el control de KONTER, por lo que
              KONTER no ser&aacute; responsable por la utilizaci&oacute;n que el
              Usuario haga de estos sitios web, ni por las Pol&iacute;ticas de
              Privacidad de los sitios web de dichos terceros.
            </p>
            <p>
              <br />
            </p>
            <h2>II.- DEL SERVICIO</h2>
            <p>
              El Servicio de KONTER consiste en una Sitio Web de
              tecnolog&iacute;a que ofrece a los Usuarios un
              autodiagn&oacute;stico financiero que les permita conocer su salud
              financiera personal y/o de su negocio con el objetivo de aplicar
              estrategias financieras que beneficien su desempe&ntilde;o.
            </p>
            <p>
              El Servicio se pone a su disposici&oacute;n para su uso personal y
              no comercial.
            </p>
            <p>
              <br />
            </p>
            <h2>A) CAPACIDAD LEGAL PARA EL USO DEL SERVICIO.</h2>
            <p>
              El Servicio que KONTER ofrece s&oacute;lo est&aacute; disponible
              para personas que tengan capacidad legal para contratar y ostenten
              la representaci&oacute;n legal de sus negocios. Si el Usuario es
              una persona moral, el representante legal de la misma ser&aacute;
              quien se registre con su nombre para poder disfrutar del Servicio.
              El Sitio Web no est&aacute; dise&ntilde;ada para ser utilizada por
              menores de edad. En caso contrario, KONTER se reserva el derecho a
              negarse a prestar el servicio, cerrar cuentas, eliminar o editar
              contenido a su entera discreci&oacute;n.
            </p>
            <p>
              <br />
            </p>
            <h2>B) LICENCIA DE USO DEL SERVICIO</h2>
            <p>
              Una vez sujeto al cumplimiento de los presentes T&eacute;rminos y
              Condiciones, KONTER le otorga al Usuario una licencia limitada con
              la finalidad de permitirle usar, acceder y disfrutar de forma
              personal los beneficios del Servicio que KONTER le brinda.
            </p>
            <p>
              <br />
            </p>
            <p>
              Esta licencia no incluye cualquier reventa o uso comercial de este
              Sitio Web o de su contenido; tampoco alg&uacute;n uso derivado de
              este Sitio Web o su contenido; o cualquier uso de la
              b&uacute;squeda de datos, robots o m&eacute;todos similares de
              recolecci&oacute;n o extracci&oacute;n de datos. Este Sitio Web
              y/o cualquier parte de esta Sitio Web no podr&aacute; ser
              reproducido, duplicado, copiado, vendido, revendido, o de otra
              manera explotado, para cualquier otro fin comercial sin el
              consentimiento expreso por escrito de KONTER. No se podr&aacute;
              enmarcar o utilizar t&eacute;cnicas de enmarcado (framing) para
              adjuntar cualquier marca comercial, logotipo u otra
              informaci&oacute;n propietaria (incluyendo im&aacute;genes,
              textos, dise&ntilde;os de p&aacute;gina o forma) de KONTER, sin el
              consentimiento expreso y por escrito de KONTER. El Usuario no
              podr&aacute; utilizar ninguna etiqueta o cualquier otro
              &ldquo;texto oculto&rdquo; que utilice el nombre o marcas
              comerciales de KONTER, sin consentimiento expreso y por escrito.
              Cualquier uso no autorizado da por terminado el permiso y la
              licencia concedida por KONTER. Se concede un derecho limitado,
              revocable y no exclusivo para crear un hiperv&iacute;nculo a la
              p&aacute;gina de inicio de KONTER siempre y cuando el
              v&iacute;nculo no presente a KONTER o su servicio de forma falsa,
              enga&ntilde;osa, despectiva o de otra manera ofensiva.
            </p>
            <p>
              <br />
            </p>
            <h2>C) USO DEL SERVICIO.</h2>
            <p>
              1.- El registro: El Usuario podr&aacute; hacer uso del Servicio
              que ofrece KONTER, siempre y cuando se encuentre registrado en el
              Sitio Web y realice el pago de la membres&iacute;a. El Usuario
              deber&aacute; registrarse creando una cuenta al proporcionar su
              direcci&oacute;n de correo electr&oacute;nico y contrase&ntilde;a.
              Una vez llenado los datos del registro se despliegan los presentes
              t&eacute;rminos y condiciones del Sitio Web y el Usuario tiene que
              elegir el recuadro de &ldquo;Aceptar&rdquo; para continuar con el
              proceso de registro, de lo contrario no podr&aacute; continuar con
              su registro.
            </p>
            <p>
              <br />
            </p>
            <p>
              La informaci&oacute;n personal debe de ser proporcionada de manera
              exacta, precisa y verdadera (en adelante, los &ldquo;Datos
              Personales&rdquo;), y el Usuario asume el compromiso de actualizar
              y/o rectificar los Datos Personales conforme resulte necesario. El
              Usuario autoriza a KONTER que utilice diversos medios para
              identificar sus datos personales, como la utilizaci&oacute;n de un
              servicio de un tercero (p.ej. Facebook, Instagram, Twitter,
              Connect). KONTER no se responsabiliza por la certeza y veracidad
              de los Datos Personales de sus Usuarios. Los Usuarios garantizan y
              responden, en cualquier caso, del defecto en cuanto a la
              veracidad, exactitud, vigencia y autenticidad de sus Datos
              Personales.
            </p>
            <p>
              <br />
            </p>
            <p>
              2.- El uso: Posterior al registro, el Usuario accede a su Cuenta
              personal mediante el ingreso de su correo electr&oacute;nico y la
              contrase&ntilde;a de seguridad personal elegida. Al ingresar el
              Usuario a su Cuenta se desplegar&aacute; el panel de inicio. A
              continuaci&oacute;n, se describe el proceso del Servicio:
            </p>
            <p>
              <br />
            </p>
            <p>
              Panel de inicio (principal): En donde el Usuario ingresar&aacute;
              sus ingresos y gastos, quien ser&aacute; el encargado de alimentar
              el Sitio Web con la informaci&oacute;n que &eacute;ste le
              solicite, tal como; i) Fechas de ingresos y egresos, ii) datos las
              facturas emitidas por el Usuario, iii) los conceptos utilizados en
              cada operaci&oacute;n, iv) Nombre y datos del cliente o proveedor
              del Usuario, v) Forma de pago y vi) Monto de la operaci&oacute;n.
            </p>
            <p>
              Flujo disponible: El Usuario podr&aacute; visualizar todos sus
              movimientos en el Sitio Web desglosado en una lista ordenada por
              fechas sobre sus i) Ingresos ii) gastos, iii) costos y iii)
              ventas. Dicha lista se puede exportar a Excell o PDF para que el
              Usuario analice la informaci&oacute;n.
            </p>
            <p>
              Estado de resultados: El Usuario tendr&aacute; acceso a una a un
              an&aacute;lisis emitido por el Sitio Web sobre la
              informaci&oacute;n financiera proporcionada por el mismo. En donde
              se podr&aacute; observar la informaci&oacute;n de i) las ventas
              totales ii) costos de operaci&oacute;n iii) gastos de
              operaci&oacute;n y iv) el margen de utilidad (grafica).
            </p>
            <p>
              Chat de soporte t&eacute;cnico: En caso de tener alguna duda
              t&eacute;cnica sobre los Servicios del Sitio Web, el Usuario
              podr&aacute; contactar al administrador del Sitio Web, por medio
              del chat puesto a su disposici&oacute;n, quien lo asesorar&aacute;
              las veces que &eacute;ste lo requiera.
            </p>
            <p>
              Mensajes: Por medio de la bandeja de mensajes, el Usuario
              recibir&aacute; notificaciones en tiempo real, sobre el estatus de
              cada proceso o tramite que &eacute;ste haya solicitado a la Sitio
              Web de KONTER.
            </p>
            <p>
              Preguntas frecuentes: El Usuario tendr&aacute; acceso a una lista
              de preguntas y respuestas para auxiliarlo en la navegaci&oacute;n
              y uso del Sitio Web de forma inmediata.
            </p>
            <h2>D) RESTRICCIONES DEL USUARIO.</h2>
            <p>
              El Usuario no podr&aacute; otorgarle permiso a un tercero de
              utilizar su cuenta. El Usuario se obliga a mantener la
              confidencialidad de su Contrase&ntilde;a de Seguridad. La Cuenta
              es personal, &uacute;nica e intransferible, y est&aacute;
              prohibido que un mismo Usuario se registre o posea m&aacute;s de
              una Cuenta. En caso de que KONTER detecte distintas Cuentas que
              contengan datos coincidentes o relacionados, KONTER se reserva el
              derecho de cancelar, suspender o inhabilitar dichas Cuentas. El
              Usuario es responsable por todas las operaciones efectuadas en su
              Cuenta, pues el acceso a la misma est&aacute; restringido al
              ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo
              del Usuario, tratando esta informaci&oacute;n como confidencial y
              de su uso exclusivo. El Usuario se compromete a notificar a KONTER
              de forma inmediata y por medio del correo electr&oacute;nico a
              hola@konter.com.mx, cualquier uso no autorizado de su Cuenta,
              as&iacute; como el ingreso por terceros no autorizados a la misma.
              Se aclara que est&aacute; prohibida la venta, cesi&oacute;n o
              transferencia de la Cuenta bajo ning&uacute;n t&iacute;tulo.
            </p>
            <p>
              <br />
            </p>
            <p>
              KONTER se reserva el derecho de rechazar cualquier solicitud de
              inscripci&oacute;n o de cancelar una inscripci&oacute;n
              previamente aceptada, sin que est&eacute; obligado a comunicar o
              exponer las razones de su decisi&oacute;n y sin que ello genere
              alg&uacute;n derecho a indemnizaci&oacute;n o resarcimiento. En
              algunos casos, se le podr&aacute; requerir al Usuario que facilite
              un documento de identidad u otro elemento que permita verificar su
              identidad para el acceso o uso del Servicio, as&iacute; como se le
              podr&aacute; impedir el acceso o uso del Servicio si se niega a
              facilitar el documento de identidad o el elemento de
              verificaci&oacute;n de identidad. KONTER se reserva el derecho
              suspender temporal o definitivamente, a aquellos Usuarios cuyos
              datos no hayan podido ser confirmados. En estos casos de
              inhabilitaci&oacute;n, se dar&aacute; de baja la Cuenta, sin que
              ello genere alg&uacute;n derecho a resarcimiento por parte de
              KONTER.
            </p>
            <p>
              <br />
            </p>
            <h2>E) DESACTIVACI&Oacute;N DE LA CUENTA.</h2>
            <p>
              El Usuario puede desactivar su cuenta de forma voluntaria en
              cualquier momento y borrar sus datos o documentos personales
              mediante el env&iacute;o de un correo a hola@konter.com.mx
              haciendo referencia tal decisi&oacute;n, para que le proporcionen
              la informaci&oacute;n necesaria para darse de baja en el sistema.
            </p>
            <p>
              <br />
            </p>
            <p>
              El Usuario contar&aacute; con cinco d&iacute;as h&aacute;biles a
              partir del correo enviado para descargar toda la
              informaci&oacute;n que requiera, ya que fenecido el plazo anterior
              la KONTER autom&aacute;ticamente eliminar&aacute; todos los
              documentos y la informaci&oacute;n compartida.
            </p>
            <p>
              <br />
            </p>
            <p>
              Una vez trascurrido el plazo se&ntilde;alado en el p&aacute;rrafo
              anterior KONTER no se hace responsable de la actividad tributaria
              del Usuario.
            </p>
            <p>
              <br />
            </p>
            <p>
              El Usuario podr&aacute;n en cualquier momento activar su cuenta
              nuevamente, debiendo llenar nuevamente los datos de registro
              solicitados.
            </p>
            <p>
              <br />
            </p>
            <p>
              KONTER se reserva el derecho de desactivar la cuenta de los
              Usuarios en caso de conductas deshonestas, o fraudulentas que
              infrinjan los presentes T&eacute;rminos y Condiciones, en dicho
              caso KONTER har&aacute; llegar al Usuario infractor un Correo
              Electr&oacute;nico notificando acerca de la desactivaci&oacute;n
              de su cuenta.
            </p>
            <p>
              <br />
            </p>
            <h2>F) CONTRASE&Ntilde;AS.</h2>
            <p>
              Al registrarse como Usuario de KONTER se le dar&aacute; la
              libertad de crear una contrase&ntilde;a que proteja su cuenta
              conformada por un c&oacute;digo alfanum&eacute;rico de
              m&iacute;nimo seis d&iacute;gitos, misma contrase&ntilde;a que
              deber&aacute; ser personal y no podr&aacute; ser compartida con
              terceros, KONTER se compromete a mantenerla en estricta
              confidencialidad. En caso que el Usuario olvide su
              contrase&ntilde;a, &eacute;ste podr&aacute; recuperarla
              seleccionando al momento de ingresar a la Sitio Web el apartado de
              &ldquo;recuperar contrase&ntilde;a&rdquo;
            </p>
            <p>
              <br />
            </p>
            <h2>III.- DEL CONTENIDO</h2>
            <h2>POL&Iacute;TICAS DE LAS PUBLICACIONES.</h2>
            <h3>Pol&iacute;ticas de las publicaciones.</h3>
            <p>
              <br />
            </p>
            <p>
              Los Usuarios conceden a KONTER el derecho a utilizar el nombre y
              logo de los Usuarios en cualquier publicaci&oacute;n o publicidad
              concerniente a KONTER con el objeto de publicitar su servicio.
            </p>
            <p>
              <br />
            </p>
            <h2>IV.- DEL PAGO.</h2>
            <p>
              La Plataforma ofrece dos tipos de membres&iacute;a que incluyen
              distintos servicios, los cuales a continuaci&oacute;n se
              mencionan:
            </p>
            <p>
              <br />
            </p>
            <p>
              1) Membres&iacute;a B&aacute;sica. El Usuario tendr&aacute; acceso
              mediante el precio se&ntilde;alado en el Sitio Web a los
              siguientes servicios:
            </p>
            <p>
              <br />
            </p>
            <p>Un Usuario principal</p>
            <p>Curso de inducci&oacute;n</p>
            <p>Reporte financiero</p>
            <p>Acceso al panel de control Konter financiero web con:</p>
            <p>Un usuario de flujo de efectivo</p>
            <p>Estado de resultados</p>
            <p>Reporte financiero mensual</p>
            <p>An&aacute;lisis grafica</p>
            <p>Siete d&iacute;as de prueba gratuitos</p>
            <p>
              2) Membres&iacute;a Avanzada. El Usuario tendr&aacute; acceso
              mediante el precio se&ntilde;alado en el Sitio Web a los
              siguientes servicios:
            </p>
            <p>
              <br />
            </p>
            <p>
              Acceso a los servicios contemplados en la membrec&iacute;a
              b&aacute;sica
            </p>
            <p>Dos Usuarios para sucursales o control de costos</p>
            <p>Asesor&iacute;a telef&oacute;nica en horario de oficina</p>
            <p>Seguimiento de resultados y estrategias cada tres meses</p>
            <p>
              Revisi&oacute;n de plan de presupuestos (aplica restricciones)
            </p>
            <p>Siete d&iacute;as de prueba gratuitos</p>
            <p>
              Los Usuarios pueden optar por membres&iacute;as anuales con una
              vigencia de 1 (un) a&ntilde;o, contado a partir de la fecha de
              pago correspondiente, con un costo &uacute;nico anual
              se&ntilde;alado en el Sitio Web.
            </p>
            <p>
              <br />
            </p>
            <p>
              Los precios publicados en la Plataforma se expondr&aacute;n con
              IVA y en Moneda Nacional.
            </p>
            <p>
              <br />
            </p>
            <p>
              El Usuario est&aacute; de acuerdo en la utilizaci&oacute;n de los
              sistemas de cobro proporcionados por KONTER como la &uacute;nica
              forma disponible para el pago del Servicio. El Sitio Web cuenta
              con servicio de cobro con Tarjetas de D&eacute;bito o
              Cr&eacute;dito. Para transferir el pago a la cuenta bancaria de
              KONTER es necesario que el Usuario registre sus datos bancarios el
              Sitio Web n. La suscripci&oacute;n y el pago ser&aacute;n
              renovados autom&aacute;ticamente por el mismo periodo de tiempo,
              realiz&aacute;ndose un cargo autom&aacute;tico a la cuenta
              registrada al finalizar el periodo de suscripci&oacute;n actual, a
              menos que la membres&iacute;a sea cancelada. Inmediatamente de
              haber realizado el pago, KONTER le har&aacute; llegar al Usuario
              una notificaci&oacute;n por correo electr&oacute;nico con el
              n&uacute;mero de confirmaci&oacute;n de su pago. Las suscripciones
              no caducan por falta de uso del servicio de pago suscrito.
            </p>
            <p>
              <br />
            </p>
            <p>
              El servicio de cobro es gestionado por un proveedor de servicios
              externo, mismo que almacena la informaci&oacute;n de las tarjetas
              de cr&eacute;dito o de d&eacute;bito de los Usuarios Compradores.
              Al hacer uso del Sitio Web, el Usuario acepta los T&eacute;rminos
              y Condiciones de dicho proveedor. KONTER no es responsable de los
              actos u omisiones de dicho proveedor, por lo que el Usuario
              reconoce que el procesamiento electr&oacute;nico del pago no
              depende ni es responsabilidad de KONTER y cualquier reclamo o
              informaci&oacute;n deber&aacute; ser dirigida directamente con
              tales proveedores del servicio. Los Usuarios acceden a que se
              realicen cargos a su tarjeta de cr&eacute;dito o de d&eacute;bito
              al utilizar los servicios de pago del Sitio Web.
            </p>
            <p>
              <br />
            </p>
            <p>
              KONTER se reserva el derecho de modificar, cambiar, agregar, o
              eliminar las tarifas vigentes, promociones y descuentos, en
              cualquier momento, lo cual ser&aacute; notificado a los Usuarios
              mediante notificaciones en el Sitio Web.
            </p>
            <p>
              <br />
            </p>
            <p>
              En caso de que no se perfeccione el pago del Usuario a KONTER, se
              le solicitar&aacute; otro m&eacute;todo de pago. Si el Usuario no
              paga la membres&iacute;a contratada, por el motivo que fuere;
              KONTER se reserva el derecho de suspender o cancelar su acceso su
              Cuenta, lo que puede resultar en una p&eacute;rdida de sus datos
              asociados en el Sitio Web. KONTER se reserva el derecho de ejercer
              las acciones judiciales y/o extrajudiciales que estime pertinentes
              para obtener el pago adeudado.
            </p>
            <p>
              <br />
            </p>
            <p>
              En caso de que el Usuario requiera de un servicio
              espec&iacute;fico de KONTER derivado del an&aacute;lisis
              financiero realizado; KONTER le har&aacute; llegar al Usuario la
              propuesta de servicios correspondiente para el desarrollo del
              servicio en espec&iacute;fico; la cual se considera aceptada desde
              el momento en que el Usuario realice el pago de los anticipos
              contemplados en dicha propuesta o desde el momento que de forma
              t&aacute;cita acepte la propuesta, enviando los documentos
              solicitados para el inicio de las gestiones a KONTER. Dicha
              propuesta constituye un contrato de prestaci&oacute;n de servicios
              profesionales celebrado por medios electr&oacute;nicos, con total
              validez jur&iacute;dica, sujet&aacute;ndose al contenido del
              mismo.
            </p>
            <p>
              <br />
            </p>
            <h2>V.- NO DEVOLUCI&Oacute;N.</h2>
            <p>
              El Usuario acepta y reconoce que una vez realizado el pago a favor
              de KONTER no existir&aacute; motivo de reembolso.
            </p>
            <p>
              <br />
            </p>
            <h2>VI.- DE LA FACTURACI&Oacute;N E IMPUESTOS.</h2>
            <p>
              KONTER emitir&aacute; la factura correspondiente al Usuario por la
              membres&iacute;a cobrada por la prestaci&oacute;n de su servicio
              en el Sitio Web de forma autom&aacute;tica; dicha factura se puede
              solicitar al correo electr&oacute;nico
              facturaci&oacute;n@konter.com.mx. dentro de un plazo de hasta 7
              (siete) d&iacute;as naturales contados a partir del cobro
              correspondiente.
            </p>
            <p>
              <br />
            </p>
            <p>
              En caso de que el Usuario desee realizar alguna
              modificaci&oacute;n en la factura emitida, contar&aacute; con un
              plazo de 5 (cinco) d&iacute;as naturales contados a partir de la
              emisi&oacute;n de &eacute;sta, para solicitar a KONTER la
              modificaci&oacute;n respectiva, siempre y cuando la fecha de su
              solicitud corresponda al mismo a&ntilde;o en que se emiti&oacute;
              la factura. Dicha solicitud deber&aacute; ser enviada a
              trav&eacute;s del correo electr&oacute;nico
              facturaci&oacute;n@konter.com.mx.
            </p>
            <p>
              <br />
            </p>
            <h2>VI.- OTRAS DISPOSICIONES.</h2>
            <h2>a) Propiedad intelectual y derechos de autor.</h2>
            <p>
              <br />
            </p>
            <p>
              En todo momento KONTER actuará en nombre propio usando su
              denominaci&oacute;n, y su marca o cualquiera otra de sus marcas
              debidamente registradas ante el Instituto Mexicano de la Propiedad
              Industrial. Queda prohibido a los Usuarios el uso de la marca,
              imagen, publicidad, nombre, logos, denominaci&oacute;n, o
              cualquier instrumento de KONTER as&iacute;́ como de Sitio Web, sin
              el previo consentimiento por escrito.
            </p>
            <p>
              <br />
            </p>
            <h2>b) Mediaci&oacute;n y jurisdicci&oacute;n.</h2>
            <p>
              <br />
            </p>
            <p>
              Los presentes T&eacute;rminos y Condiciones se regir&aacute;n e
              interpretar&aacute;n exclusivamente por las leyes y juzgados de la
              Ciudad de M&eacute;rida, en particular respecto de mensajes de
              datos, contrataci&oacute;n electr&oacute;nica y comercio
              electr&oacute;nico se regir&aacute; por lo dispuesto por la
              legislaci&oacute;n federal respectiva y los tratados
              internacionales. Ante cualquier disputa, conflicto,
              reclamaci&oacute;n o controversia que resulte de los
              T&eacute;rminos y Condiciones, deber&aacute;n someterse
              primeramente a procedimientos de la Ley de Justicia Alternativa de
              la Ciudad de M&eacute;rida, Yucat&aacute;n de no llegarse a un
              acuerdo las partes expresamente se someten a la
              jurisdicci&oacute;n de los tribunales competentes de la Ciudad de
              M&eacute;rida, Yucat&aacute;n, renunciando en consecuencia a
              cualquier fuero que en raz&oacute;n de su domicilio presente o
              futuro pudiera corresponderles.
            </p>
            <p>
              <br />
            </p>
            <h2>c) Alcances</h2>
            <p>
              <br />
            </p>
            <p>
              El presente Servicio se proporciona &uacute;nicamente con fines
              informativos y no deber&aacute; considerarse como un servicio
              financiero hasta que se concrete la propuesta del servicio y/o el
              pago total de la misma. KONTER no asume responsabilidad alguna
              respecto del alcance que pueda d&aacute;rsele a las
              recomendaciones u opiniones emitidas mediante en el Sitio Web o
              derivado del Sitio Web.
            </p>
            <p>
              <br />
            </p>
            <h2>d) Notificaciones.</h2>
            <p>
              <br />
            </p>
            <p>
              KONTER, podr&aacute; realizar notificaciones acerca del Servicio
              que ofrece por medio de correo electr&oacute;nico,
              haci&eacute;ndoselo llegar al Usuario por cualquier duda o
              aclaraci&oacute;n. El Usuario podr&aacute; notificar a, KONTER a
              trav&eacute;s del correo electr&oacute;nico: hola@konter.com.mx
            </p>
            <p>
              <br />
            </p>
            <h2>e) Consentimiento.</h2>
            <p>
              <br />
            </p>
            <p>
              El Usuario conviene, al aceptar estos T&eacute;rminos y
              Condiciones, que en la celebraci&oacute;n del presente Contrato no
              ha mediado error, dolo, mala fe, lesi&oacute;n ni ning&uacute;n
              otro vicio del consentimiento que pudiera invalidarlo. De igual
              manera, una vez aceptados los T&eacute;rminos y Condiciones
              ser&aacute;n irrenunciables las disposiciones contenidas en los
              mismos.
            </p>
            <p>
              <br />
            </p>
            <h2>f) Sanciones.</h2>
            <p>
              <br />
            </p>
            <p>
              KONTER podr&aacute;, seg&uacute;n lo estime necesario, enviar
              avisos, suspender, bloquear o rescindir el acceso al Sitio Web a
              cualquier Usuario, o eliminar cualquier contenido, incluyendo,
              entre otros, si, a) se incumple con alguna Ley o con estos
              T&eacute;rminos y Condiciones, b) si est&aacute; utilizando el
              Sitio Web de KONTER de una forma que podamos considerar
              fraudulenta y c) si no puede verificar su identidad como usuario o
              si proporcion&oacute; informaci&oacute;n err&oacute;nea. En el
              caso de sanci&oacute;n de un Usuario, &eacute;ste podr&aacute; ser
              temporal o definitivamente removidos del sistema.
            </p>
            <p>
              <br />
            </p>
            <h2>g) Vigencia.</h2>
            <p>
              <br />
            </p>
            <p>
              La relaci&oacute;n entre KONTER y los Usuarios permanecer&aacute;́
              vigente en tanto el Usuario cuente con una cuenta activa en el
              Sitio Web.
            </p>
            <p>
              <br />
            </p>
            <h2>h) Modificaciones.</h2>
            <p>
              <br />
            </p>
            <p>
              KONTER se reserva el derecho de modificar o actualizar los
              presentes T&eacute;rminos y Condiciones en cualquier momento, tras
              lo cual, solicitará nuevamente la aceptaci&oacute;n de los
              Usuarios para poder continuar haciendo uso de la Sitio Web.
            </p>
            <p>
              <br />
            </p>
            <p>
              En el caso de que el Usuario decida no aceptar las modificaciones
              a KONTER, &eacute;sta suspender&aacute; el servicio en la cuenta
              del Usuario. Si el Usuario quisiera reactivar su cuenta,
              deber&aacute; solicitarlo y aceptar los nuevos T&eacute;rminos y
              Condiciones. KONTER se obliga a reactivar la cuenta del Usuario
              previa aceptaci&oacute;n de las modificaciones a los
              T&eacute;rminos y Condiciones. Transcurrido un plazo de 10
              d&iacute;as sin que el Usuario se hubiere manifestado al respecto,
              KONTER podr&aacute; proceder dar de baja definitivamente la
              multicitada cuenta.
            </p>
            <p>
              <br />
            </p>
            <h2>i) Restricci&oacute;n de la responsabilidad</h2>
            <h2>
              <br />
            </h2>
            <h2>i) Veracidad de la informaci&oacute;n</h2>
            <p>
              <br />
            </p>
            <p>
              Como se menciona en repetidas ocasiones; el Usuario es quien
              alimenta el contenido de su cuenta, por lo que el resultado del
              Servicio no depende de KONTER, ya que &eacute;ste no es el
              propietario de la informaci&oacute;n y de los documentos otorgados
              por el Usuario, por ello no ser&aacute; responsable respecto de la
              existencia, veracidad, calidad, estado, integridad o legitimidad
              de la informaci&oacute;n proporcionado por los Usuarios. Tampoco
              es responsable de la capacidad para contratar de los Usuarios
              entre s&iacute; o de la veracidad de los Datos Personales por
              ellos ingresados. Cada Usuario conoce y acepta ser el
              exclusivamente responsable de la informaci&oacute;n que anexa a la
              Sitio Web y por lo tanto; deslinda de toda responsabilidad a
              KONTER de los resultados que se obtengan por el servicio, si el
              Usuario no proporciona informaci&oacute;n completa,
              ver&iacute;dica y adecuada.
            </p>
            <p>
              <br />
            </p>
            <h2>
              ii) Interrupci&oacute;n del servicio por mejoras y adaptaciones de
              la Sitio Web
            </h2>
            <p>
              <br />
            </p>
            <p>
              KONTER no garantiza que el Sitio Web o cualquiera del Servicio o
              el contenido del mismo, est&eacute;n siempre disponibles o no se
              interrumpan. Nos reservamos el derecho en cualquier momento y sin
              aviso previo para mejorar, modificar, alterar, suspender o
              interrumpir de manera permanente toda la Sitio Web o algunas
              partes de la misma o cualquier Servicio o el contenido del mismo,
              as&iacute; como a restringir o prohibir el acceso al mismo.
            </p>
            <p>
              <br />
            </p>
            <h2>iii) Fallas en el sistema, en el servidor o en internet.</h2>
            <p>
              <br />
            </p>
            <p>
              El Usuario acepta tener vigente su antivirus o cualquier barrera
              de seguridad que emplee en el sistema donde utilice el Sitio Web.
              KONTER no se responsabiliza por cualquier da&ntilde;o, perjuicio o
              p&eacute;rdida al Usuario causados por fallas en el sistema, en el
              servidor o en internet. Tampoco ser&aacute; responsable por
              cualquier virus que pudiera infectar el equipo del Usuario como
              consecuencia del acceso, uso o examen de su Sitio Web, o a
              ra&iacute;z de cualquier transferencia de datos, archivos,
              im&aacute;genes o texto. Los Usuarios NO podr&aacute;n imputarle
              responsabilidad alguna ni exigir alguna indemnizaci&oacute;n, en
              virtud de perjuicios resultantes de dificultades t&eacute;cnicas o
              fallas en los sistemas o en internet. El Usuario acepta
              expresamente no: (i) publicar, distribuir o transmitir cualquier
              virus, archivo u otro c&oacute;digo, archivo, malware, spyware o
              cualquier otro software da&ntilde;ino que pudiera implantarse o
              transmitirse por terceros a trav&eacute;s del Sitio Web; (ii)
              utilizar el Sitio Web para acceder a las cuentas de otras personas
              sin su autorizaci&oacute;n; y/o, (iii) intentar vulnerar las
              medidas de seguridad del Sitio Web, u obtener las
              contrase&ntilde;as de otras personas.
            </p>
            <p>
              <br />
            </p>
            <h2>iv) Responsabilidad de las actuaciones del Usuario</h2>
            <p>
              <br />
            </p>
            <p>
              El Usuario reconoce que el Sitio Web, &uacute;nicamente tiene
              fines informativos, por lo que no deber&aacute; de considerarlo
              como una asesor&iacute;a financiera. Por ello; KONTER no es
              responsable de las recomendaciones otorgadas, ni garantiza la
              efectividad, integridad, exactitud, resultado y/o efectividad de
              la implementaci&oacute;n de los an&aacute;lisis financieros
              presentados en su Sitio Web, ya que el resultado depende del
              empe&ntilde;o y dedicaci&oacute;n del el Usuario, siendo
              &uacute;nicamente un Sitio Web de recomendaciones o gu&iacute;as
              financieras para los Usuarios. Contrario sensu, es responsabilidad
              &uacute;nica y exclusiva de KONTER el contenido de los documentos
              desarrollados y entregados a los Usuarios suscitados de la
              propuesta de servicios contemplado en el numeral IV del presente
              documento, toda vez que el Usuario terminen de liquidar a favor de
              KONTER el pago acordado en dicha propuesta de servicios enviada al
              Usuario. Cualquier resultado negativo derivado de la
              informaci&oacute;n proporcionada por los Usuario a KONTER que sea
              falsa, incompleta, modificada, incorrecta o inexacta, antes,
              durante o posterior del desarrollo de los servicios contemplados
              en la propuesta de servicios, ser&aacute; responsabilidad
              exclusiva del Usuario y sin responsabilidad de ning&uacute;n tipo
              por parte de KONTER.
            </p>
            <p>
              <br />
            </p>
            <p>
              El Usuario expresamente est&aacute; de acuerdo en que toda la
              asesor&iacute;a, indicaciones y recomendaciones brindadas por
              KONTER ser&aacute;n apegadas a la ley y la ejecuci&oacute;n de las
              mismas. Por ni un motivo se realizar&aacute;n actuaciones fuera de
              la ley o estrategias tendientes a evadir impuestos o dilucidar
              obligaciones del Usuario. En caso de que el Usuario por su cuenta
              realice alg&uacute;n movimiento fuera de la ley, &eacute;l es
              exclusivamente responsable de los efectos que dicha
              actuaci&oacute;n pudiera ocasionarle. Por ello; Usuario acepta
              expresamente abstenerse, de manera enunciativa m&aacute;s no
              limitativa, de: (i) utilizar el Sitio Web para cualquier fin
              il&iacute;cito, en violaci&oacute;n a las leyes, regulaciones, y
              disposiciones fiscales, tributarias, mercantiles o de cualquier
              otra naturaleza, que resulten aplicables o pudieran serlo en un
              futuro; (ii) Anexar cualquier documento que contengan
              v&iacute;nculos que inicien descargas de material il&iacute;cito o
              que infrinjan derechos de propiedad intelectual o industrial, y
              que se relacionen en cualquier forma con KONTER; (iii) participar
              en cualquier actividad en medios virtuales que se vinculen de
              forma alguna con KONTER y/o, (iv) realizar cualquier conducta que
              resulte ilegal y que vincule de forma directa o indirecta a
              KONTER.
            </p>
            <p>
              <br />
            </p>
            <p>
              Los Usuarios comprenden y aceptan que KONTER tiene la
              obligaci&oacute;n de reportar y poner a disposici&oacute;n de las
              autoridades correspondientes las Cuentas de los Usuarios que
              cometan o se presuma que cometen delitos relacionados en el
              p&aacute;rrafo anterior, que ponga en riesgo la integridad de un
              tercero o de KONTER, para que se realicen las indagaciones
              pertinentes, dejando a salvo los derechos correspondientes y la
              acci&oacute;n legal que a KONTER le pudiese corresponder.
            </p>
            <p>
              <br />
            </p>
            <p>
              KONTER no responder&aacute; sobre los documentos, declaraciones,
              pagos, estrategias o cualquier otra actuaci&oacute;n realizada por
              el Usuario.
            </p>
            <p>
              <br />
            </p>
            <h2>v) Mal uso de la Sitio Web</h2>
            <p>
              <br />
            </p>
            <p>
              El Usuario acepta ser el &uacute;nico responsable por el mal uso
              de la Sitio Web y del Servicio prove&iacute;do por KONTER.
              Asimismo, en caso que un mal uso genere da&ntilde;os o perjuicios
              para KONTER, el Usuario ser&aacute;́ responsable del pago hasta por
              el monto total al que asciendan los da&ntilde;os ocasionados y
              ser&aacute;́ susceptible del pago de una indemnizaci&oacute;n
              equiparable a los da&ntilde;os y perjuicios como pena
              convencional, dejando a salvo los derechos correspondientes y
              acci&oacute;n legal que a KONTER le pudiese corresponder.
            </p>
            <p>
              <br />
            </p>
            <h2> vi) Fuerza Mayor</h2>
            <p>
              <br />
            </p>
            <p>
              En casos de fuerza mayor, KONTER no se hace responsable por
              ning&uacute;n incumplimiento o retraso en el Servicio que presta;
              para efectos de este p&aacute;rrafo, se consideran casos de fuerza
              mayor los siguientes: a) desastres naturales, b) guerras, c)
              huelgas, d) suspensiones temporales o permanentes de los sistemas
              de proveedores a internet o paquetes de datos, entre otros.
            </p>
            <p>
              <br />
            </p>
            <p>
              Si cualquier disposici&oacute;n de estos T&eacute;rminos y
              Condiciones se considerara ilegal, nula o inexigible, ya sea en su
              totalidad o en parte, de conformidad con cualquier
              legislaci&oacute;n, dicha disposici&oacute;n o parte de esta se
              considerar&aacute; que no forma parte de estos T&eacute;rminos y
              Condiciones, dejando la legalidad, validez y exigibilidad del
              resto de las disposiciones de estos T&eacute;rminos y Condiciones
              no se ver&aacute; afectada.
            </p>
            <p>
              <br />
            </p>
            <p>
              Estos T&eacute;rminos y Condiciones constituyen el contrato
              &iacute;ntegro y el entendimiento entre las partes en
              relaci&oacute;n con el objeto.
            </p>
          </Fade>
          <Fade bottom duration={1000} delay={1000} distance="30px">
            <p className="hero-cta justify-content-center">
              <Link className="cta-btn cta-btn--hero" to="/">
                Regresar
              </Link>
            </p>
          </Fade>
        </Container>
      </section>
    </>
  );
};
